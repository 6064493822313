/* eslint-disable import/prefer-default-export */
import useUserStore from 'stores/user';

// Format currency value according to set locale and currency.
export const formatCurrency = (
  value,
  minimumFractionDigits = 0,
  maximumFractionDigits = null
) => {
  const userStore = useUserStore.getState();
  const style = {
    style: 'currency',
    currency: userStore.user.currency,
    minimumFractionDigits,
  };
  if (maximumFractionDigits) {
    style.maximumFractionDigits = maximumFractionDigits;
  }
  return Number(value).toLocaleString(userStore.user.region, style);
};
