import React, { useRef, useState, useEffect } from 'react';
import { func, shape } from 'prop-types';
import clsx from 'clsx';
import { useClickAway } from 'react-use';
import useBreakpoint, { MOBILE, TABLET_LANDSCAPE } from 'hooks/useBreakpoint';
import { text } from 'polyglot/polyglot';
import { getUserProfileImg } from 'utils/getUserProfileImg';

import usePrizePlanStore from 'stores/prizeplan-modal';
import { useChatStore } from 'stores/chat';
import useUserStore from 'stores/user';
import useMenuStore from 'stores/menu';
import useSettingsStore from 'stores/settings';
import useGameStore, { GAME_REPLAY } from 'stores/game';
import useTutorialStore from 'stores/tutorial';
import { shallow } from 'zustand/shallow';

import Scrollbars from 'components/Scrollbars/Scrollbars';
import BingoRooms from 'components/BingoRooms/BingoRooms';
import Button from 'components/Button/Button';
import UserProfileContainer from 'components/TopbarPanel/UserProfileContainer/UserProfileContainer';
import SettingButtons from 'components/TopbarPanel/SettingButtons/SettingButtons';
import LayoutButtons from 'components/TopbarPanel/LayoutButtons/LayoutButtons';
import Modal from 'components/Modal/Modal';
import SettingsWindow from 'components/SettingsWindow/SettingsWindow';
import BonusNotification from 'components/GameInfoPanel/BonusNotification/BonusNotification';

import ReturnArrow from 'assets/svg/return-arrow.svg';
import Hamburger from 'assets/svg/hamburger-icon.svg';
import ArrowHead from 'assets/svg/arrowhead.svg';
import logoSv from 'assets/theme/images/logo-mobile-86x28.png';
import logoEn from 'assets/theme/images/logo-rs-mobile-86x28.png';

import styles from './MobileView.module.scss';

const isChatExpandedSelector = (state) => [
  state.isChatExpanded,
  state.setIsChatExpanded,
];
const menuOpenSelector = (state) => [state.isMenuOpen, state.setIsMenuOpen];
const userLinkSelector = (state) => state.user;
const gameStateSelector = (state) => state.gameState;
const userAvatarSelector = (state) => state.settings.userAvatar;
const setTutorialIsActiveSelector = (state) => [
  state.tutorialIsActive,
  state.setTutorialIsActive,
];
const setCogwheelPositionSelector = (state) => state.setCogwheelPosition;
const setSpeakerPositionSelector = (state) => state.setSpeakerPosition;
const tutorialStepSelector = (state) => [
  state.tutorialStep,
  state.setTutorialStep,
];

function MobileView({ modalVisibility, displayBonus }) {
  const setModalVisibility = usePrizePlanStore(
    (state) => state.setModalVisibility
  );
  const [isChatExpanded, setIsChatExpanded] = useChatStore(
    isChatExpandedSelector,
    shallow
  );
  const [isSideMenuActive, setSideMenuActive] = useMenuStore(
    menuOpenSelector,
    shallow
  );
  const user = useUserStore(userLinkSelector);
  const userAvatar = useSettingsStore(userAvatarSelector);
  const userAvatarSrc = getUserProfileImg(userAvatar);
  const breakpoint = useBreakpoint();
  const innerRef = useRef(null);
  const settingsTextPositionRef = useRef();
  const soundIconPositionRef = useRef();

  const [settingsModalIsVisible, setSettingsModalVisibiliy] = useState(false);
  const [tutorialIsActive, setTutorialIsActive] = useTutorialStore(
    setTutorialIsActiveSelector,
    shallow
  );
  const [tutorialStep, setTutorialStep] = useTutorialStore(
    tutorialStepSelector,
    shallow
  );
  const setCogwheelPosition = useTutorialStore(setCogwheelPositionSelector);
  const setSpeakerPosition = useTutorialStore(setSpeakerPositionSelector);
  const gameState = useGameStore(gameStateSelector);
  const isReplay = gameState === GAME_REPLAY;

  const updateSettingsTextPosition = () => {
    if (settingsTextPositionRef.current) {
      setCogwheelPosition(
        settingsTextPositionRef.current.getBoundingClientRect()
      );
      setSpeakerPosition(soundIconPositionRef.current.getBoundingClientRect());
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateSettingsTextPosition);
    updateSettingsTextPosition();
    return () => {
      window.removeEventListener('resize', updateSettingsTextPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSideMenuActive && tutorialIsActive) {
      setTimeout(() => {
        updateSettingsTextPosition();
      }, 350);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSideMenuActive]);

  const openSidemenu = () => {
    setSideMenuActive(true);
  };
  const closeSideMenu = () => {
    if (tutorialIsActive) return;
    setSideMenuActive(false);
  };

  const onClickSetting = () => {
    setSettingsModalVisibiliy(!settingsModalIsVisible);
  };

  useClickAway(innerRef, closeSideMenu);

  const locale = text.locale().toLocaleLowerCase();

  return (
    <>
      <div
        className={clsx(styles.wrapper, {
          [styles.isHidden]: isSideMenuActive || isChatExpanded,
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
        })}
      >
        {settingsModalIsVisible && (
          <Modal willJustifyContentLeft willAlignContentBottom>
            <div className={styles.modalWrapper}>
              <SettingsWindow />
              <Button
                isModalCloseButton
                onClick={onClickSetting}
                className={styles.modalCloseButton}
              />
            </div>
          </Modal>
        )}
        {breakpoint === MOBILE ? (
          <a href={user.returnButtonURL}>
            <Button isIcon className={styles.returnButton} onClick={() => {}}>
              <ReturnArrow />
            </Button>
          </a>
        ) : (
          <div className={styles.tabletSettingsContainer}>
            <SettingButtons isWhite />
          </div>
        )}
        {breakpoint !== MOBILE && <LayoutButtons />}
        <Button isIcon className={styles.menuButton} onClick={openSidemenu}>
          <Hamburger />
        </Button>
      </div>
      <div
        className={clsx(styles.menuWrapper, {
          [styles.active]: isSideMenuActive,
        })}
      >
        <div
          ref={innerRef}
          className={clsx(styles.sideMenu, {
            [styles.active]: isSideMenuActive,
            [styles.noTransition]:
              tutorialStep === 1 || tutorialStep === 2 || tutorialStep === 3,
          })}
        >
          <Scrollbars className={styles.scrollable}>
            {user.isAuthenticated && (
              <div className={styles.userBox}>
                <span>{user.nickname}</span>
                <img src={userAvatarSrc} alt="" className={styles.userIcon} />
              </div>
            )}
            <UserProfileContainer />
            {user.isAuthenticated && displayBonus && !isReplay && (
              <div className={styles.bonus}>
                <BonusNotification bonus={displayBonus} isInSidemenu />
              </div>
            )}
            <ul className={styles.menu}>
              {!isReplay && (
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      setModalVisibility();
                      closeSideMenu();
                    }}
                  >
                    {text.t('topPanel.listItem1')}
                  </button>
                </li>
              )}
              <li>
                <button
                  type="button"
                  onClick={() => {
                    closeSideMenu();
                    modalVisibility();
                  }}
                >
                  {text.t('topPanel.listItem2')}
                </button>
              </li>
              {!isReplay && (
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      closeSideMenu();
                      setIsChatExpanded(true);
                    }}
                  >
                    {text.t('topPanel.listItem3')}
                  </button>
                </li>
              )}
              {user.isAuthenticated && (
                <li ref={settingsTextPositionRef}>
                  <button
                    type="button"
                    onClick={() => {
                      closeSideMenu();
                      onClickSetting();
                    }}
                  >
                    {text.t('topPanel.listItem4')}
                  </button>
                </li>
              )}
              {user.isAuthenticated && !isReplay && (
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      updateSettingsTextPosition();
                      setTutorialStep(1);
                      setTutorialIsActive(true);
                    }}
                  >
                    {text.t('topPanel.listItem5')}
                  </button>
                </li>
              )}
              <a href={user.returnButtonURL}>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      closeSideMenu();
                    }}
                  >
                    {text.t('topPanel.listItem6')}
                  </button>
                </li>
              </a>
              <li ref={soundIconPositionRef}>
                <SettingButtons onlySoundButton />
              </li>
            </ul>
            {isSideMenuActive && user.bingo_room_links && !isReplay && (
              <BingoRooms />
            )}
            <img
              src={locale !== 'sv' ? logoEn : logoSv}
              alt="Bingo"
              className={styles.logo}
            />
          </Scrollbars>
          <button
            type="button"
            className={clsx(styles.menuCloseButton, {
              [styles.active]: isSideMenuActive,
            })}
            onClick={closeSideMenu}
          >
            <ArrowHead />
          </button>
        </div>
      </div>
    </>
  );
}

MobileView.propTypes = {
  modalVisibility: func.isRequired,
  displayBonus: shape(),
};

MobileView.defaultProps = {
  displayBonus: null,
};

export default MobileView;
