// eslint-disable-next-line import/no-unresolved
import { Container, Text, BitmapText, BitmapFont } from 'pixi.js';
import gsap from 'gsap';
import { text } from 'polyglot/polyglot';
import { formatCurrency } from 'utils/currency';

export default class PrizeInfo {
  constructor(
    priceValue,
    multiplier,
    total,
    animType,
    onComplete,
    defaultScale = 0.5
  ) {
    this.animType = animType;
    this.defaultScale = defaultScale;
    this.onComplete = onComplete;
    this.container = new Container();
    this.total = total;

    this.potTotal = 0;

    this.prizeLabel = new Text(formatCurrency(priceValue), {
      fontFamily: 'Apercu Pro',
      fontSize: 38 * defaultScale,
      fill: 0xfadc9c,
    });
    this.prizeLabel.alpha = 0;
    this.prizeLabel.anchor.set(0.5, 0.5);
    this.container.addChild(this.prizeLabel);

    this.multiplierLabel = new Text(`x${multiplier}`, {
      fontFamily: 'Apercu Pro Bold',
      fontSize: 60 * defaultScale,
      fill: 0xfadc9c,
    });
    this.multiplierLabel.alpha = 0;
    this.multiplierLabel.anchor.set(0.5, 0.5);
    this.multiplierLabel.y =
      this.prizeLabel.y + this.prizeLabel.height + this.defaultScale * 14;
    this.container.addChild(this.multiplierLabel);

    this.potHeader = new Text(text.t('potbooster.totalPot'), {
      fontFamily: 'Apercu Pro',
      fontSize: 38 * defaultScale,
      fill: 0xfadc9c,
    });
    this.potHeader.alpha = 0;
    this.potHeader.anchor.set(0.5, 0.5);
    this.potHeader.y =
      this.multiplierLabel.y +
      this.multiplierLabel.height +
      this.defaultScale * 20;
    this.container.addChild(this.potHeader);

    BitmapFont.from(
      'Apercu Pro Bold',
      {
        fontFamily: 'Apercu Pro Bold',
        fontSize: 88 * defaultScale,
        fill: 0xfadc9c,
      },
      {
        resolution: window.devicePixelRatio,
        chars:
          '0123456789,. abcdefghijklmnopqrstuvwxyzkr€$ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      }
    );

    this.potValue = new BitmapText('', {
      fontName: 'Apercu Pro Bold',
      fontSize: 88 * defaultScale,
      fill: 0xfadc9c,
    });
    this.potValue.alpha = 0;
    this.potValue.anchor.set(0.5, 0.5);
    this.potValue.y =
      this.potHeader.y + this.potHeader.height + this.defaultScale * 40;
    this.container.addChild(this.potValue);
  }

  show() {
    const tl = gsap.timeline({ paused: true });
    tl.to(
      [this.prizeLabel, this.multiplierLabel, this.potHeader, this.potValue],
      {
        startAt: { alpha: 0 },
        alpha: 1,
        duration: 1,
      }
    );
    tl.to(this, {
      startAt: { potTotal: 0 },
      potTotal: this.total,
      duration: 3,
      ease: 'power3.inOut',
      onUpdate: () => {
        this.potValue.text = formatCurrency(this.potTotal);
      },
      onComplete: () => {
        this.potValue.text = formatCurrency(this.total);
      },
    });
    tl.call(
      () => {
        this.bumpPotValue();
      },
      null,
      '-=0.4'
    );
    tl.call(() => {
      this.onComplete();
    });
    tl.timeScale(1.2);
    if (this.animType === 'fast') {
      tl.timeScale(4);
    } else if (this.animType === 'medium') {
      tl.timeScale(2);
    }
    tl.play();
  }

  bumpPotValue() {
    const tl = gsap.timeline();
    tl.to(this.potValue.scale, {
      x: 1.3,
      y: 1.3,
      duration: 0.3,
      ease: 'power1.out',
    });
    tl.to(this.potValue.scale, {
      x: 1,
      y: 1,
      duration: 0.8,
      ease: 'elastic.out(1, 0.3)',
    });
  }

  destroy() {
    // console.log('destroy');
  }
}
