import React, { useEffect, useRef, useState } from 'react';
import { number, func, bool, shape } from 'prop-types';
import { text } from 'polyglot/polyglot';
import clsx from 'clsx';
import { gsap } from 'gsap';
import { getCssVariable } from 'utils/style-variables';
import Button from 'components/Button/Button';
import { formatCurrency } from 'utils/currency';

import Coin from 'assets/svg/bonus-coin.svg';
import DashedLines from 'assets/svg/dashed-lines.svg';
import Star from 'assets/svg/coin-star.svg';

import useClientProgress from 'hooks/useClientProgress';
import styles from './BonusDisplay.module.scss';

function BonusDisplay({
  displayBonus,
  purchaseTotal,
  onClose,
  onClickConfirm,
  isDisabled,
  isReceipt,
  sumNotIncludedInBonus,
}) {
  const { getCurrentProgress, updateCurrentProgress } = useClientProgress();
  const [progress] = useState(getCurrentProgress());
  const { goal } = displayBonus.qualification;

  const sumToSubtract =
    sumNotIncludedInBonus > purchaseTotal
      ? purchaseTotal
      : sumNotIncludedInBonus;
  const purchaseTotalForUser = purchaseTotal - sumToSubtract;
  const sumWithBonus = goal - (progress + purchaseTotalForUser);
  const bonusIsReached = sumWithBonus <= 0;
  const overlayRef = useRef();
  const thirdCoinRef = useRef();
  const secondCoinRef = useRef();
  const firstCoinRef = useRef();
  const starRef = useRef();
  const isMountedRef = useRef(true);
  const moneyValueRef = useRef();

  const barAnimDuration = 0.5;
  const bonusBarAnimDuration = 0.3;
  const coinAnimDelay = barAnimDuration - 0.1;
  const coinAnimDuration = 0.5;

  const returnRefData = (index, value) => {
    switch (index) {
      case 0:
        if (value === 'ref') return thirdCoinRef.current;
        if (value === 'startRotation') return 0;
        if (value === 'endRotation') return -360;
        break;
      case 1:
        if (value === 'ref') return secondCoinRef.current;
        if (value === 'startRotation') return 60;
        if (value === 'endRotation') return -300;
        break;
      case 2:
        if (value === 'ref') return firstCoinRef.current;
        if (value === 'startRotation') return 180;
        if (value === 'endRotation') return -180;
        break;
      default:
        return '';
    }
  };

  const spreadCoins = () => {
    if (!isMountedRef.current) return;
    const animDuration = bonusBarAnimDuration - 0.1;
    const rotation = -360;
    const duration = 0.3;
    const ease = 'easeOut';
    gsap.to(`.${styles.third}`, {
      x: -70,
      y: -70,
      rotation,
      duration,
      ease,
      delay: animDuration,
    });
    gsap.to(`.${styles.second}`, {
      x: -20,
      y: -80,
      rotation,
      duration,
      ease,
      delay: animDuration + 0.05,
    });
    gsap.to(`.${styles.first}`, {
      x: 15,
      y: -40,
      rotation,
      duration,
      ease,
      delay: animDuration + 0.1,
    });
    gsap.to(`.${styles.bonusAchievedText}`, {
      opacity: 1,
      duration: 0.3,
      ease,
      delay: bonusBarAnimDuration + 0.6,
    });
  };

  const rotateStars = () => {
    if (!isMountedRef.current) return;
    const animationDelay = coinAnimDelay + 0.2;
    const starDelay = coinAnimDelay + 0.4 + coinAnimDuration;
    for (let i = 0; i < 3; i++) {
      gsap.fromTo(
        returnRefData(i, 'ref'),
        {
          rotation: returnRefData(i, 'startRotation'),
        },
        {
          rotation: returnRefData(i, 'endRotation'),
          duration: coinAnimDuration,
          delay: animationDelay + i * 0.2,
        }
      );
    }
    gsap.to(starRef.current, {
      scale: 2.5,
      y: 4,
      duration: 0.1,
      delay: starDelay,
    });
    gsap.to(starRef.current, {
      scale: 1,
      y: 0,
      duration: 0.1,
      delay: starDelay + 0.1,
    });
    if (isReceipt) {
      gsap.to(moneyValueRef.current, {
        scale: 1.2,
        y: -1,
        duration: 0.2,
        ease: 'easeOut',
        delay: bonusBarAnimDuration + 0.2,
      });
      gsap.to(moneyValueRef.current, {
        scale: 1,
        y: 0,
        duration: 0.2,
        ease: 'easeOut',
        delay: bonusBarAnimDuration + 0.4,
      });
    }
  };

  const expandBar = () => {
    if (!isMountedRef.current) return;
    const newTotalInPercent = ((progress + purchaseTotalForUser) / goal) * 100;
    gsap.to(overlayRef.current, {
      width: `${newTotalInPercent > 100 ? 100 : newTotalInPercent}%`,
      duration:
        isReceipt && bonusIsReached ? bonusBarAnimDuration : barAnimDuration,
    });
    if (isReceipt && bonusIsReached) {
      const barColor = getCssVariable('--bonus-green-bar-receipt');
      gsap.to(overlayRef.current, {
        backgroundColor: barColor,
        ease: 'easeOut',
        duration: 0.3,
        delay: bonusBarAnimDuration,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isMountedRef.current) {
        expandBar();
        rotateStars();
        if (isReceipt && bonusIsReached) {
          spreadCoins();
        } else {
          rotateStars();
        }
      }
    }, 600);
    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnBonusItem = () => {
    if (isReceipt && bonusIsReached) {
      return <div className={styles.block} />;
    }
    let bonusText;
    switch (displayBonus.payout.payoutType) {
      case 'FixedPayment':
        bonusText = formatCurrency(displayBonus.payout.fixedAmount);
        break;
      case 'PercentagePayment':
        bonusText = text.t('bonusModal.purchaseConfirmation.bonusPayoutText');
        break;
      case 'Voucher':
        bonusText = text.t('bonusModal.purchaseConfirmation.voucherText', {
          amount: displayBonus.payout.voucherAmount,
        });
        break;
      default:
        bonusText = '';
        break;
    }
    return <p className={styles.totalBonusText}>{bonusText}</p>;
  };

  const returnBonusReachedText = () => {
    let bonusText;
    switch (displayBonus.payout.payoutType) {
      case 'FixedPayment':
        bonusText = text.t('bonusModal.receipt.moneyPayout', {
          amount: formatCurrency(displayBonus.payout.fixedAmount),
        });
        break;
      case 'PercentagePayment':
        bonusText = text.t('bonusModal.receipt.percentPayout');
        break;
      case 'Voucher':
        bonusText = text.t('bonusModal.receipt.voucherPayout');
        break;
      default:
        bonusText = '';
    }
    return <p className={styles.bonusAchievedText}>{bonusText}</p>;
  };

  useEffect(() => {
    if (isReceipt) {
      updateCurrentProgress(purchaseTotalForUser);
    }

    if (!purchaseTotalForUser) {
      isMountedRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseTotalForUser]);

  if (!purchaseTotalForUser && isReceipt) {
    return null;
  }

  if (!purchaseTotalForUser) {
    return (
      <div className={styles.buttonWrapper}>
        <Button className={styles.button} onClick={onClose} isSecondary>
          {text.t('purchaseModal.purchase.cancelButton')}
        </Button>
        <Button
          className={styles.button}
          onClick={onClickConfirm}
          isDisabled={isDisabled}
        >
          {text.t('purchaseModal.purchase.confirmButton')}
        </Button>
      </div>
    );
  }

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isReceipt]: isReceipt,
      })}
    >
      {!isReceipt ? (
        <p className={clsx(styles.bonusText, styles.smallerMargin)}>
          {text.t('bonusModal.purchaseConfirmation.firstParagraph1')}
          {'\u00A0'}
          <b>
            {bonusIsReached
              ? text.t('bonusModal.purchaseConfirmation.firstParagraph2')
              : formatCurrency(sumWithBonus)}
          </b>
        </p>
      ) : (
        <p className={clsx(styles.bonusText, styles.largerMargin)}>
          {text.t('bonusModal.receipt.firstParagraph1')}
          {'\u00A0'}
          <b ref={moneyValueRef}>
            {bonusIsReached
              ? text.t('bonusModal.receipt.firstParagraph2')
              : formatCurrency(sumWithBonus)}
          </b>
          {'\u00A0'}
          {bonusIsReached
            ? text.t('bonusModal.receipt.firstParagraph3')
            : text.t('bonusModal.receipt.firstParagraph4')}
        </p>
      )}
      {!isReceipt && (
        <p className={styles.bonusText}>
          {bonusIsReached
            ? text.t('bonusModal.purchaseConfirmation.firstParagraph3')
            : text.t('bonusModal.purchaseConfirmation.firstParagraph4')}
        </p>
      )}
      <div className={styles.background}>
        <div
          className={clsx(styles.overlay, {
            [styles.bonusAchieved]: bonusIsReached,
            [styles.isReceipt]: isReceipt,
          })}
          ref={overlayRef}
          style={{
            width: `${(progress / goal) * 100}%`,
          }}
        />
        {!isReceipt && !bonusIsReached && (
          <DashedLines
            className={styles.dashedLines}
            style={{
              left: `${((progress + purchaseTotalForUser) / goal) * 100}%`,
            }}
          />
        )}
        {!isReceipt && (
          <div
            className={styles.sum}
            style={{ width: `${(progress / goal) * 100}%` }}
          />
        )}
        {isReceipt && bonusIsReached && returnBonusReachedText()}
      </div>
      <div
        className={clsx(styles.coin, styles.first, {
          [styles.bonusIsReached]: bonusIsReached,
        })}
      >
        <div ref={firstCoinRef}>
          <Coin className={styles.coinIcon} />
        </div>
      </div>
      <div
        className={clsx(styles.coin, styles.second, {
          [styles.bonusIsReached]: bonusIsReached,
        })}
      >
        <div ref={secondCoinRef}>
          <Coin className={styles.coinIcon} />
        </div>
      </div>
      <div
        className={clsx(styles.coin, styles.third, {
          [styles.bonusIsReached]: bonusIsReached,
        })}
      >
        <div ref={thirdCoinRef}>
          <Coin className={styles.coinIcon} />
          <span className={styles.starWrapper} ref={starRef}>
            <Star className={styles.star} />
          </span>
        </div>
      </div>
      {returnBonusItem()}
      {!isReceipt && (
        <div className={styles.buttonWrapper}>
          <Button className={styles.button} onClick={onClose} isSecondary>
            {text.t('purchaseModal.purchase.cancelButton')}
          </Button>
          <Button
            className={styles.button}
            onClick={onClickConfirm}
            isDisabled={isDisabled}
          >
            {text.t('purchaseModal.purchase.confirmButton')}
          </Button>
        </div>
      )}
    </div>
  );
}

BonusDisplay.propTypes = {
  displayBonus: shape().isRequired,
  purchaseTotal: number.isRequired,
  onClose: func,
  onClickConfirm: func,
  isDisabled: bool,
  isReceipt: bool,
  sumNotIncludedInBonus: number.isRequired,
};

BonusDisplay.defaultProps = {
  onClose: () => {},
  onClickConfirm: () => {},
  isDisabled: false,
  isReceipt: false,
};

export default BonusDisplay;
