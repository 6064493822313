import React from 'react';
import { shape, bool } from 'prop-types';
import clsx from 'clsx';
import { text } from 'polyglot/polyglot';
import useBreakpoint, { TABLET_LANDSCAPE } from 'hooks/useBreakpoint';
import { formatCurrency } from 'utils/currency';

import Coins from 'assets/svg/bonus-coins.svg';

import useClientProgress from 'hooks/useClientProgress';
import styles from './BonusNotification.module.scss';

function BonusNotification({ bonus, isInSidemenu }) {
  const breakpoint = useBreakpoint();
  const { getCurrentProgress } = useClientProgress();
  const progress = getCurrentProgress();
  const { goal } = bonus.qualification;

  const returnBonusItem = () => {
    let bonusText;
    switch (bonus.payout.payoutType) {
      case 'FixedPayment':
        bonusText = formatCurrency(bonus.payout.fixedAmount);
        break;
      case 'PercentagePayment':
        bonusText = text.t('bonusModal.purchaseView.bonusPayoutText');
        break;
      case 'Voucher':
        bonusText = text.t('bonusModal.purchaseView.voucherText', {
          amount: bonus.payout.voucherAmount,
        });
        break;
      default:
        bonusText = '';
        break;
    }
    return (
      <p
        className={clsx(styles.price, {
          [styles.isInSidemenu]: isInSidemenu,
        })}
      >
        {bonusText}
      </p>
    );
  };

  return (
    <div className={styles.wrapper}>
      <p
        className={clsx(styles.paragraph, {
          [styles.isLandscape]: breakpoint === TABLET_LANDSCAPE,
          [styles.isInSidemenu]: isInSidemenu,
        })}
      >
        <span>{formatCurrency(goal - progress)}</span>
        {text.t('bonusModal.purchaseView.purchaseView')}
      </p>
      <div className={styles.progressBarWrapper}>
        <div className={styles.progressBar}>
          <div
            className={styles.progress}
            style={{ width: `${(progress / goal) * 100}%` }}
          />
        </div>
        <Coins
          className={clsx(styles.coins, {
            [styles.isInSidemenu]: isInSidemenu,
          })}
        />
      </div>
      {returnBonusItem()}
    </div>
  );
}

BonusNotification.propTypes = {
  bonus: shape().isRequired,
  isInSidemenu: bool,
};

BonusNotification.defaultProps = {
  isInSidemenu: false,
};

export default BonusNotification;
