/* eslint-disable no-underscore-dangle */
import { Howl } from 'howler';
import { text } from 'polyglot/polyglot';
// import spriteData from 'data/soundSprite';
import svVoSpriteMP3 from 'assets/theme/sound/default.mp3';
import svDefaultJSON from 'assets/theme/sound/default.json';
import enVoSpriteMP3 from 'assets/theme/sound/english.mp3';
import enDefaultJSON from 'assets/theme/sound/english.json';

import axios from 'axios';

export default class SoundManager {
  constructor() {
    if (this._instance) {
      // eslint-disable-next-line no-constructor-return
      return SoundManager.instance;
    }

    this.isMuted = false;
    this.announcerSound = false;
    this.voSprite = null;
    this.spriteData = null;
    this.lastPlayedVO = null;
    this.browserIsInteracted = false;

    const locale = text.locale();
    const availableLocales = {
      sv: { json: svDefaultJSON, mp3: svVoSpriteMP3 },
      en: { json: enDefaultJSON, mp3: enVoSpriteMP3 },
      nb: { json: enDefaultJSON, mp3: enVoSpriteMP3 },
    };

    const getData = () => {
      axios
        .get(availableLocales[locale].json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then((response) => {
          this.spriteData = response.data;
          this.voSprite = new Howl({
            src: [availableLocales[locale].mp3],
            sprite: this.spriteData,
            onunlock: () => {
              this.browserIsInteracted = true;
            },
          });
        })
        .catch((error) => {
          // handle error
          console.log('error on retrieving sprite data', error);
        });
    };

    getData();

    // eslint-disable-next-line no-constructor-return
    return this;
  }

  static get instance() {
    if (!this._instance) {
      this._instance = new SoundManager();
    }

    return this._instance;
  }

  playVO(id) {
    if (this.browserIsInteracted) {
      if (this.voSprite && id !== this.lastPlayedVO) {
        if (!this.isMuted && this.announcerSound) {
          this.voSprite.play(id);
        }
        if (id !== 'bingoend' && id !== 'bingo' && id !== 'jackpot') {
          this.lastPlayedVO = id;
        }
      }
    }
  }

  stopVO(id) {
    if (this.voSprite) {
      this.voSprite.stop(id);
    }
  }

  muteApp(value) {
    this.isMuted = value;
  }

  updateAnnouncerSound(value) {
    this.announcerSound = value;
  }

  startBgSound() {}

  stopBgSound() {}
}
