import React, { useEffect, useRef } from 'react';
import { bool } from 'prop-types';
import clsx from 'clsx';
import api from 'utils/api';
import useGameStore, { GAME_REPLAY } from 'stores/game';
import { shallow } from 'zustand/shallow';
import { formatCurrency } from 'utils/currency';

import { text } from 'polyglot/polyglot';
import useUserStore from 'stores/user';
import WalletIcon from 'assets/svg/wallet.svg';
import Button from 'components/Button/Button';

import styles from './GameWallet.module.scss';

const userSelector = (state) => [state.user, state.setUser];
const gameStateSelector = (state) => state.gameState;

function GameWallet({ insideUserProfile, isTutorialView }) {
  const [user, setUser] = useUserStore(userSelector, shallow);
  const gameState = useGameStore(gameStateSelector);
  const isReplay = gameState === GAME_REPLAY;
  const clock = useRef(null);

  const updateBalance = async () => {
    await api
      .get('/api/CustomerTransaction/CombinedBalance')
      .then((res) => {
        setUser({
          balance: res.totalValue,
        });
      })
      .catch((err) => {
        console.log('error on getting balance: ', err);
      });
  };

  const updateCustomerBonus = async () => {
    await api
      .get('/api/CustomerBonus')
      .then((res) => {
        setUser({
          customerBonus: res,
        });
      })
      .catch((err) => {
        console.log('error on getting bonus information: ', err);
      });
  };

  useEffect(() => {
    if (insideUserProfile && !isReplay) {
      updateCustomerBonus();
    }
    if (!clock.current && insideUserProfile && !isReplay) {
      clock.current = setInterval(() => {
        updateBalance()
          // eslint-disable-next-line no-promise-executor-return
          .then(() => new Promise((resolve) => setTimeout(resolve, 2000)))
          .then(() => updateCustomerBonus());
      }, 20000);
      return () => clearInterval(clock.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const insertMoney = () => {
    if (user.callback_deposit && typeof user.callback_deposit === 'function') {
      user.callback_deposit();
    }
  };

  const onClickGameWallet = () => {
    user.callback_wallet();
  };

  return (
    <div className={styles.outerWrapper}>
      <WalletIcon
        className={clsx(styles.walletIcon, {
          [styles.notInUserProfile]: !insideUserProfile,
          [styles.isTutorialView]: isTutorialView,
        })}
      />
      <div
        className={clsx(styles.wrapper, {
          [styles.notInUserProfile]: !insideUserProfile,
          [styles.isTutorialView]: isTutorialView,
        })}
      >
        {user.callback_wallet ? (
          <div
            className={styles.walletLink}
            role="button"
            onClick={onClickGameWallet}
            tabIndex={0}
            onKeyPress={onClickGameWallet}
          >
            <span
              className={clsx(styles.walletText, {
                [styles.notInUserProfile]: !insideUserProfile,
              })}
            >
              {text.t('gameWallet.walletText')}
            </span>
          </div>
        ) : (
          <div>
            <span
              className={clsx(styles.walletText, {
                [styles.notInUserProfile]: !insideUserProfile,
              })}
            >
              {text.t('gameWallet.walletText')}
            </span>
          </div>
        )}
        <div
          className={clsx(styles.secondContainer, {
            [styles.notInUserProfile]: !insideUserProfile,
          })}
        >
          {user.balance && (
            <span>{formatCurrency(Math.floor(user.balance))}</span>
          )}
          <Button
            onClick={insertMoney}
            isClean
            className={clsx(styles.addMoney, {
              [styles.notInUserProfile]: !insideUserProfile,
              [styles.isTutorialView]: isTutorialView,
            })}
          >
            {text.t('gameWallet.buttonText')}
          </Button>
        </div>
      </div>
    </div>
  );
}

GameWallet.propTypes = {
  insideUserProfile: bool,
  isTutorialView: bool,
};

GameWallet.defaultProps = {
  insideUserProfile: false,
  isTutorialView: false,
};

export default GameWallet;
