/* eslint-disable no-underscore-dangle */
import * as signalR from '@microsoft/signalr';
import useUserStore from 'stores/user';
import useSettingsStore from 'stores/settings';
import useChatTextStore from 'stores/chat-text';
import { useChatStore } from 'stores/chat';
import { text } from 'polyglot/polyglot';

export default class ChatManager {
  constructor() {
    if (this._instance) {
      // eslint-disable-next-line no-constructor-return
      return ChatManager.instance;
    }

    this.userState = useUserStore.getState();
    this.chatState = useChatStore.getState();
    this.chatTextStore = useChatTextStore.getState();

    this.chatconnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.userState.user.apiURL}/chathub`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([0, 5000, 20000, 50000])
      .build();

    // Start of reconnection if signal is lost
    this.chatconnection.onreconnecting((error) => {
      console.log('chat tries to reconnect with SignalR: ', error);
      this.chatState.setCurrentRoom(null);
      this.chatState.updateChatrooms([]);
    });
    this.chatconnection.onclose((err) => {
      console.log('chat disconnect error from SignalR: ', err);
    });
    this.chatconnection.onreconnected(() => {
      console.log('chat reconnection was made');
      if (this.userState.user.nickname === '') {
        this.chatconnection.invoke(
          'JoinPublicChatAnonymous',
          this.userState.user.chatId
        );
      } else {
        this.chatconnection.invoke(
          'JoinPersonalChat',
          this.userState.user.nickname,
          this.userState.user.access_token
        );
        const chatState = useChatStore.getState();
        if (chatState.connectionKey) {
          this.chatconnection.invoke(
            'JoinPublicChat',
            this.userState.user.chatId,
            this.userState.user.nickname,
            chatState.connectionKey
          );
        }
      }
    });
    // End of reconnection if signal is lost

    this.chatconnection.on('HubWasJoined', (message) => {
      if (message.connectionKey) {
        this.chatState.setConnectionKey(message.connectionKey);
        this.chatconnection.invoke(
          'JoinPublicChat',
          this.userState.user.chatId,
          this.userState.user.nickname,
          message.connectionKey
        );
      }
    });

    // this.chatconnection.on('ChatWasJoinedByHost', () => {
    //   // console.log('ChatWasJoinedByHost: ', message);
    // });

    // this.chatconnection.on('ChatWasLeft', () => {
    //   // console.log('ChatWasLeft: ', message);
    // });

    // this.chatconnection.on('ChatWasLeftByHost', () => {
    //   // console.log('ChatWasLeftByHost: ', message);
    // });

    // this.chatconnection.on('ChatAccept', () => {
    //   // console.log('ChatAccept: ', message);
    // });

    this.chatconnection.on('ChatCreatedByHost', (message) => {
      // console.log('ChatCreatedByHost: ', message);
      const chatStore = useChatStore.getState();
      const { chatrooms } = chatStore;
      const index = chatrooms.findIndex(
        (room) => room.roomId === message.newChatIdentifier
      );
      if (index === -1) {
        const newRoom = {
          roomId: message.newChatIdentifier,
          icon: !message.isPrivate ? -2 : message.avatarId,
          isPrivate: message.isPrivate,
          unreadCount: 0,
          messages: message.chatHistoricalMessages
            ? message.chatHistoricalMessages
            : [],
          chatName: message.newChatDisplayName,
          displayName: message.newChatDisplayName || '',
          chatParticipants: message.chatParticipants,
          isAdminChat: true,
        };
        this.chatState.addNewChatroom(newRoom);
        this.chatState.setCurrentRoom(newRoom);
      } else {
        // The room exists, can this happen, should be exchanged?
      }
    });

    this.chatconnection.on('BlockedFromChat', (message) => {
      console.log('BlockedFromChat: ', message);
      this.chatTextStore.setUserIsBlocked(true);
    });

    this.chatconnection.on('BlacklistedWord', () => {
      const userStore = useUserStore.getState();
      const settingsStore = useSettingsStore.getState();
      const chatStore = useChatStore.getState();
      const { chatrooms, currentRoom } = chatStore;
      const index = chatrooms.findIndex(
        (room) => room.roomId === currentRoom.roomId
      );
      if (index !== -1) {
        const newMessage = {
          avatarId: settingsStore.settings.userAvatar,
          isChatHost: false,
          message: text.t('blacklistedWordMessage'),
          isBlackListed: true,
          sent: Date.now(),
          userName: userStore.user.nickname,
        };
        chatStore.addNewMessage(index, newMessage);
        if (
          chatStore.currentRoom.roomId !== chatrooms[index].roomId ||
          chatStore.bingoRoomsIsVisible
        ) {
          chatStore.updateUnreadCount(index);
        }
        chatStore.setLastUpdated(Date.now());
      }
    });

    this.chatconnection.on('ChatIsClosing', () => {
      // Message only for groupchat
      // console.log('ChatIsClosing: ', message);
      this.chatTextStore.setGroupChatIsClosed(true);
    });

    this.chatconnection.on('ClosedChat', (message) => {
      // Message only for groupchat
      console.log('ClosedChat: ', message);
      this.chatTextStore.setGroupChatIsClosed(true);
    });

    this.chatconnection.on('ChatStarted', (message) => {
      // Message only for groupchat
      console.log('ChatStarted: ', message);
      this.chatTextStore.setGroupChatIsClosed(false);
    });

    this.chatconnection.on('ChatWasJoined', (message) => {
      const chatStore = useChatStore.getState();
      const { chatrooms } = chatStore;

      // Should not display more then 8 chats
      if (chatrooms.length >= 8) return;

      const index = chatrooms.findIndex(
        (room) => room.roomId === message.newChatIdentifier
      );
      if (index === -1) {
        const newRoom = {
          roomId: message.newChatIdentifier || message.chatName,
          icon: !message.isPrivate ? -2 : message.avatarId,
          isPrivate: message.isPrivate,
          unreadCount: 0,
          messages: message.chatHistoricalMessages
            ? message.chatHistoricalMessages
            : [],
          chatName: message.newChatDisplayName,
          displayName: message.newChatDisplayName || '',
          chatParticipants: message.chatParticipants,
          isAdminChat: false,
        };
        this.chatState.addNewChatroom(newRoom);
        this.chatState.setCurrentRoom(newRoom);
      } else {
        // The room exists, can this happen, should be exchanged?
      }
    });

    // this.chatconnection.on('ChatWasLeft', () => {
    //   // console.log('ChatWasLeft: ', message);
    // });

    this.chatconnection.on('ChatParticipants', (message) => {
      const chatStore = useChatStore.getState();
      const { chatrooms } = chatStore;
      const index = chatrooms.findIndex(
        (room) => room.roomId === message.chatName
      );
      if (index !== -1) {
        chatStore.updateParticipants(index, message.chatParticipants);
        chatStore.setLastUpdated(Date.now());
      }
    });

    this.chatconnection.on('ChatRequest', (message) => {
      this.chatState.addActiveChatRequest(message);
    });

    this.chatconnection.on('ChatUsersChanged', (message) => {
      const chatStore = useChatStore.getState();
      const { chatrooms } = chatStore;
      const index = chatrooms.findIndex(
        (room) => room.roomId === message.chatName
      );
      if (index !== -1) {
        chatStore.updateParticipantCount(index, message.numberOfChatUsers);
        chatStore.setLastUpdated(Date.now());
      }
    });

    this.chatconnection.on('ReceiveMessage', (message) => {
      if (message.type === 3) {
        const chatStore = useChatStore.getState();
        const { chatrooms } = chatStore;
        const index = chatrooms.findIndex(
          (room) => room.roomId === message.chatName
        );
        if (index !== -1) {
          chatStore.addNewMessage(index, message);
          if (
            chatStore.currentRoom.roomId !== chatrooms[index].roomId ||
            chatStore.bingoRoomsIsVisible
          ) {
            chatStore.updateUnreadCount(index);
          }
          chatStore.setLastUpdated(Date.now());
        }
      }
    });

    this.chatconnection
      .start()
      .then(() => {
        if (this.userState.user.nickname === '') {
          this.chatconnection.invoke(
            'JoinPublicChatAnonymous',
            this.userState.user.chatId
          );
        } else {
          this.chatconnection.invoke(
            'JoinPersonalChat',
            this.userState.user.nickname,
            this.userState.user.access_token
          );
        }
      })
      .catch((err) => console.error(err.toString()));
  }

  static get instance() {
    if (!this._instance) {
      this._instance = new ChatManager();
    }

    return this._instance;
  }

  //  Room should come from the user, isPrivate should depend on what room
  sendMessage(messageText, roomId, isPrivate) {
    const chatState = useChatStore.getState();
    try {
      this.chatconnection.invoke(
        'SendMessage',
        messageText, // message
        roomId, //  chatName/guid
        this.userState.user.nickname, //  userName
        chatState.connectionKey, // connection key
        isPrivate // is a private chat
      );
    } catch (err) {
      console.log('error on sending message to chat');
    }
  }

  requestChatParticipiantList(roomId) {
    try {
      this.chatconnection.invoke(
        'GetChatUsers',
        roomId //  chatName/guid
      );
    } catch (err) {
      console.log('error on sending message to chat');
    }
  }

  requestPrivateChat(recipient) {
    const chatState = useChatStore.getState();
    try {
      this.chatconnection.invoke(
        'RequestPrivateChat',
        this.userState.user.nickname, // fromUserIndentifier
        recipient, //  toUserIdentifier
        chatState.connectionKey // connection key
      );
    } catch (err) {
      console.log('error on sending private chat request');
    }
  }

  answerRequest(reqId, answer) {
    const chatState = useChatStore.getState();
    try {
      this.chatconnection.invoke(
        'PrivateChatRequestResponse',
        reqId, // requestId
        answer, //  accept
        chatState.connectionKey // connection key
      );
    } catch (err) {
      console.log('error on sending private chat request');
    }
  }

  leaveChatRoom(chatId, nickname) {
    const chatState = useChatStore.getState();
    try {
      this.chatconnection.invoke(
        'LeaveChat',
        chatId, // chatGuid
        nickname, //  nickname
        chatState.connectionKey // connection key
      );
    } catch (err) {
      console.log('error on sending private chat request');
    }
  }

  leaveAllChats(nickname) {
    const chatState = useChatStore.getState();
    this.chatconnection.invoke(
      'LeaveAllChats',
      nickname, //  nickname
      chatState.connectionKey // connection key
    );
  }
}
